import React, { useEffect } from 'react';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';

import { redirectOnError, getPreviewToken } from '@utils/helpers';
import reportError from '@utils/reportError';
import api from '@services/api';

import Homepage from './homepage';

export const getServerSideProps = async ({ req, query }: GetServerSidePropsContext) => {
  try {
    const token = getPreviewToken(req.headers.cookie);

    const { data } = await api.get('/homepage/', { headers: { authorization: token } });
    const unsubscribe_status = query?.unsubscribe_status ?? null;
    return {
      props: { homepage: data, unsubscribe_status },
    };
  } catch (e) {
    reportError(e);

    return redirectOnError(e);
  }
};

const Index = (props: any) => {
  const { isReady, push } = useRouter();
  const { unsubscribe_status } = props;
  const cleanUrl = async () => await push({}, undefined, { shallow: true });

  useEffect(() => {
    if (unsubscribe_status && isReady) {
      cleanUrl().catch((e) => console.error(e));
    }
  }, [isReady]);

  return <Homepage {...props} />;
};

export default Index;
