import styled from 'styled-components';

import { FONT_WEIGHT } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;

  & > button:first-of-type {
    margin-right: 20px;
  }
`;

export const Message = styled.h3`
  margin: ${OFFSET.M} 0;
  font-weight: ${FONT_WEIGHT.BOOK};
`;

export const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 9999,
  },
};
