import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { GetServerSidePropsContext } from 'next';
import { StatusCodes } from 'http-status-codes';

import api from '@services/api';
import { Layout } from '@components/layout';
import reportError from '@utils/reportError';
import { getPreviewToken, redirectOnError } from '@utils/helpers';
import { Homepage as HomepageType } from '@definitions/homepage.types';
import { ContentBody } from '@components/contentBody';
import { HeroCarousel } from '@components/heroCarousel';
import { renderWhenTrue } from '@utils/rendering';
import { HomeEndSection } from '@components/homeEndSection';
import { PromptModal } from '@components/promptModal';

export const getServerSideProps = async ({ req }: GetServerSidePropsContext) => {
  try {
    const token = getPreviewToken(req.headers.cookie);

    const { data } = await api.get('/homepage/', { headers: { authorization: token } });

    return {
      props: { homepage: data },
    };
  } catch (e) {
    reportError(e);

    return redirectOnError(e);
  }
};

interface HomepageProps {
  homepage: HomepageType;
  unsubscribe_status: string | undefined;
}

const Homepage = ({ homepage, unsubscribe_status }: HomepageProps) => {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(typeof unsubscribe_status === 'string');
  const headerTitle = intl.formatMessage({
    id: 'homepage.headerTitle',
    defaultMessage: 'PLATFORM: Buy Art Online | Discover New Artists',
  });
  const headerDescription = intl.formatMessage({
    id: 'homepage.headerDescription',
    defaultMessage:
      'Platform is an artist-driven brand selling one-of-a-kind artworks, exclusive artist-designed products, and fine art prints. We partner with today’s most influential artists and galleries to offer regular releases of new things worth collecting.',
  });

  const renderHeroCarousel = renderWhenTrue(() => <HeroCarousel slides={homepage.header} />);

  const handleClosePrompt = () => {
    try {
      setModalOpen(false);
    } catch (e) {
      reportError(e);
    }
  };
  const unsubscribedModalMessage =
    unsubscribe_status === String(StatusCodes.OK)
      ? 'You have successfully unsubscribed!'
      : 'You have already unsubscribed from email marketing';

  return (
    <Layout title={headerTitle} defaultDescription={headerDescription} seo={homepage.seo}>
      {renderHeroCarousel(!!homepage.header?.length)}
      <ContentBody body={homepage.body || []} hasSpacingTop={true} hasSpacingBottom={true} />
      <PromptModal message={unsubscribedModalMessage} open={modalOpen} onCancel={handleClosePrompt} />
    </Layout>
  );
};

export default Homepage;
