import React, { MouseEvent } from 'react';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { always } from 'ramda';

import { Button, BUTTON_SIZE } from '@components/form';
import { renderWhenTrue } from '@utils/rendering';

import { Container, Actions, modalStyles, Message } from './promptModal.styles';

export interface PromptModalProps {
  onCancel?: (e?: MouseEvent<HTMLButtonElement>) => any;
  onConfirm?: (e: MouseEvent<HTMLButtonElement>) => any;
  message: string;
  open: boolean;
  isDecision?: boolean;
}

export const PromptModal = ({
  onCancel = () => ({}),
  onConfirm = () => ({}),
  message,
  open = false,
  isDecision = false,
}: PromptModalProps) => {
  const renderDecisionActions = renderWhenTrue(
    always(
      <Actions>
        <Button size={BUTTON_SIZE.XXL} fullWidth onClick={(e) => onCancel(e)}>
          <FormattedMessage id="promptModal.close" defaultMessage="Close" />
        </Button>
        <Button size={BUTTON_SIZE.XXL} fullWidth onClick={(e) => onConfirm(e)}>
          <FormattedMessage id="promptModal.confirm" defaultMessage="Confirm" />
        </Button>
      </Actions>
    )
  );
  const renderCloseButton = renderWhenTrue(
    always(
      <Actions>
        <Button size={BUTTON_SIZE.XXL} fullWidth onClick={(e) => onCancel(e)}>
          <FormattedMessage id="promptModal.close" defaultMessage="Close" />
        </Button>
      </Actions>
    )
  );

  return (
    <Modal isOpen={open} onRequestClose={() => onCancel()} style={modalStyles}>
      <Container>
        <Message>{message}</Message>
        {renderDecisionActions(isDecision)}
        {renderCloseButton(!isDecision)}
      </Container>
    </Modal>
  );
};
